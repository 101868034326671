import { render, staticRenderFns } from "./CateringHomeBanner.vue?vue&type=template&id=2918854f&scoped=true&"
import script from "./CateringHomeBanner.vue?vue&type=script&lang=js&"
export * from "./CateringHomeBanner.vue?vue&type=script&lang=js&"
import style0 from "./CateringHomeBanner.vue?vue&type=style&index=0&id=2918854f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2918854f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconChevronRight: require('/opt/build/repo/components/icons/IconChevronRight.vue').default})
